import React, { useRef, useState } from "react";
import Section from "../components/Section";
import SEO from "../components/SEO";
import styled from "styled-components";
import fourOhFourGif from "../../static/404.gif";
import { below } from "../styles/Breakpoints";

const StyledFourOhFour = styled.div`
  p {
    font-size: 1.5rem;
    font-size: clamp(1rem, 0.5rem + 2vw, 1.5rem);
    @supports not (clamp(1rem, 2vw, 1.5rem)) {
      ${below.mobileL`
        font-size: 1rem; 
      `}
    }
    font-weight: 600;
    text-align: center;
  }

  img {
    max-width: 500px;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export default function FourOhFour() {
  return (
    <>
      <SEO  />
      <Section fullScreen>
        <StyledFourOhFour>
          <img src={fourOhFourGif} alt="James Vanderbeek crying" />
          <p>Sorry, that page doesn't exist!</p>
        </StyledFourOhFour>
      </Section>
    </>
  );
}
